/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark .widget {
  border: none;
  box-shadow: none;
}

/*---------Palette Box--------*/
body.dark .color-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(14, 23, 38, 0.59);
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  background: rgba(14, 23, 38, 0.59);
}
body.dark .color-box .cl-example {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 6px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: -10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
body.dark .color-box:hover .cl-example {
  -webkit-transform: scale(1.09);
  transform: scale(1.09);
}
body.dark .color-box .cl-info {
  flex: 1;
}
body.dark .color-box .cl-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: #888ea8;
}
body.dark .color-box .cl-info span {
  font-size: 14px;
  color: #888ea8;
  font-weight: 600;
}

