/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
  Live Search
*/
body.dark .filtered-list-search {
  margin-top: 0;
  margin-bottom: 50px;
}
body.dark .filtered-list-search form > div {
  position: relative;
}
body.dark .filtered-list-search form input {
  border: 1px solid #1b2e4b;
  -webkit-box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
}
body.dark .filtered-list-search form input:focus {
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
}
body.dark .filtered-list-search form button {
  border-radius: 50%;
  padding: 6px 7px;
  position: absolute;
  right: 5px;
  top: 5px;
}
body.dark .filtered-list-search form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bfc9d4;
}
body.dark .filtered-list-search form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #bfc9d4;
}
body.dark .filtered-list-search form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #bfc9d4;
}
body.dark .filtered-list-search form input:-moz-placeholder {
  /* Firefox 18- */
  color: #bfc9d4;
}
body.dark .searchable-container {
  /* max-width: 1140px; */
  margin: 0 auto;
}
body.dark .searchable-items {
  padding: 13px;
  border: 1px solid #1b2e4b;
  border-radius: 10px;
}
body.dark .searchable-container .searchable-items {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
body.dark .searchable-container .items {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 0.625rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  justify-content: space-between;
  background: #1b2e4b;
  margin-bottom: 15px;
  border-radius: 14px;
  padding: 13px 18px;
  width: 100%;
  color: #0e1726;
  min-width: 625px;
  cursor: pointer;
  -webkit-box-shadow: 0px 2px 9px 2px rgba(31, 45, 61, 0.1);
  box-shadow: 0px 2px 9px 2px rgba(31, 45, 61, 0.1);
  transition: transform 0.3s;
}
body.dark .searchable-container .items:hover {
  -webkit-transform: translateY(0) scale(1.03);
  transform: translateY(0) scale(1.03);
  transform: translateY(0) scale(1.01);
}
body.dark .searchable-container .items .user-profile {
  display: flex;
}
body.dark .searchable-container .items .user-profile img {
  width: 43px;
  height: 43px;
  border-radius: 5px;
}
body.dark .searchable-container .items .user-name p, body.dark .searchable-container .items .user-work p, body.dark .searchable-container .items .user-email p {
  margin-bottom: 0;
  color: #d3d3d3;
  font-weight: 600;
}
body.dark .searchable-container .items .action-btn p {
  margin-bottom: 0;
  color: #506690;
  cursor: pointer;
  font-weight: 600;
}
body.dark .searchable-container .items:hover .serial-number p, body.dark .searchable-container .items:hover .user-name p, body.dark .searchable-container .items:hover .user-work p, body.dark .searchable-container .items:hover .user-email p, body.dark .searchable-container .items:hover .action-btn p {
  color: #00ab55;
}

/*
    Search Box
*/
body.dark .search-input-group-style.input-group .input-group-prepend .input-group-text svg {
  color: #4361ee;
}
body.dark .search-input-group-style input {
  border: none;
  border-radius: 4px;
}

