/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark .layout-px-spacing {
  min-height: calc(100vh - 142px) !important;
}
body.dark [class*=g-dot-] {
  position: relative;
}
body.dark [class*=g-dot-]:before {
  position: absolute;
  padding: 4px;
  content: "";
  background: transparent;
  border-radius: 50%;
  top: 15px;
  left: 0;
  border: 1px solid #515365;
}
body.dark .g-dot-primary:before {
  border-color: #2196f3;
  background: #2195f3;
}
body.dark .g-dot-warning:before {
  border-color: #e2a03f;
  background: #e2a03f;
}
body.dark .g-dot-success:before {
  border-color: #00ab55;
  background: #00ab55;
}
body.dark .g-dot-danger:before {
  border-color: #e7515a;
  background: #e7515a;
}
body.dark .mail-content-container.mailInbox [data-original-title=Restore], body.dark .mail-content-container.sentmail [data-original-title=Restore], body.dark .mail-content-container.important [data-original-title=Restore], body.dark .mail-content-container.spam [data-original-title=Restore] {
  display: none;
}
body.dark .mail-content-container.trashed [data-original-title=Reply], body.dark .mail-content-container.trashed [data-original-title=Forward], body.dark .mail-content-container.trashed [data-original-title=Print] {
  display: none;
}
body.dark .form-check-input {
  background-color: #515365;
  border-color: #515365;
}
body.dark .mail-box-container {
  position: relative;
  display: flex;
  border-radius: 8px;
  background-color: #0e1726;
  height: calc(100vh - 155px);
  box-shadow: 5px 5px 14px #02030a, -5px -5px 14px #0a0d26;
  border: 1px solid #0e1726;
}
body.dark .mail-box-container .avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.83333rem;
}
body.dark .mail-box-container .avatar {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  font-size: 12px;
}
body.dark .mail-box-container .avatar .avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #181d3a;
  color: #ebedf2;
}
body.dark .mail-overlay {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100%;
  background: #3b3f5c !important;
  z-index: 4 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
body.dark .mail-overlay.mail-overlay-show {
  display: block;
  opacity: 0.7;
}
body.dark .tab-title {
  padding: 33px 15px;
  max-width: 115px;
  border-right: 1px solid #191e3a;
}
body.dark .tab-title .mail-btn-container {
  padding: 0 30px;
}
body.dark .tab-title #btn-compose-mail {
  transform: none;
  background: #009688;
  border: none !important;
  padding: 7px 9px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #191e3a !important;
  width: 40px;
  margin: 0 auto;
}
body.dark .tab-title #btn-compose-mail:hover {
  box-shadow: none;
}
body.dark .tab-title #btn-compose-mail svg {
  width: 22px;
  height: 22px;
}
body.dark .tab-title.mail-menu-show {
  left: 0;
  width: 100%;
  height: 100%;
}
body.dark .tab-title .nav-pills .nav-link.active, body.dark .tab-title .nav-pills .show > .nav-link {
  background-color: transparent;
  color: #22c7d5;
  font-weight: 600;
  fill: rgba(27, 85, 226, 0.2392156863);
}
body.dark .tab-title .mail-categories-container {
  margin-top: 27px;
  padding: 0 0;
}
body.dark .tab-title .mail-sidebar-scroll {
  position: relative;
  margin: auto;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 254px);
}
body.dark .tab-title .mail-sidebar-scroll .ps__rail-y {
  right: -15px !important;
}
body.dark .tab-title .nav-pills:nth-child(1) .nav-item:first-child a.nav-link {
  border-top: 1px solid #191e3a;
  padding-top: 24px;
}
body.dark .tab-title .nav-pills a.nav-link {
  position: relative;
  font-weight: 600;
  color: #888ea8;
  padding: 14px 0px 14px 0px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  text-align: center;
  border-radius: 0;
  border-bottom: 1px solid #191e3a;
  transition: none;
}
body.dark .tab-title .nav-pills .nav-link.active svg, body.dark .tab-title .nav-pills .show > .nav-link svg {
  color: #22c7d5;
}
body.dark .tab-title .nav-pills a.nav-link svg {
  width: 19px;
  height: 19px;
  margin-bottom: 7px;
  fill: rgba(0, 23, 55, 0.08);
  color: #888ea8;
}
body.dark .tab-title .nav-pills a.nav-link span.nav-names {
  display: block;
  letter-spacing: 1px;
  padding: 0;
}
body.dark .tab-title .nav-pills a.nav-link .mail-badge {
  background: #009688;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  padding: 3px 0;
  height: 19px;
  width: 19px;
  color: #191e3a;
  font-weight: 700;
  font-size: 10px;
  top: 7px;
}
body.dark .group-section {
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
  color: #009688;
  letter-spacing: 1px;
  margin-top: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
}
body.dark .group-section svg {
  color: #009688;
  margin-right: 6px;
  align-self: center;
  width: 17px;
  height: 17px;
}
body.dark .tab-title .nav-pills.group-list .nav-item a {
  position: relative;
  padding: 6px 45px 6px 41px;
  letter-spacing: 1px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #888ea8;
  border-bottom: none !important;
}
body.dark .tab-title .nav-pills.group-list .nav-item a.g-dot-primary.active:before {
  background: #2196f3;
}
body.dark .tab-title .nav-pills.group-list .nav-item a.g-dot-warning.active:before {
  background: #e2a03f;
}
body.dark .tab-title .nav-pills.group-list .nav-item a.g-dot-success.active:before {
  background: #009688;
}
body.dark .tab-title .nav-pills.group-list .nav-item a.g-dot-danger.active:before {
  background: #e7515a;
}
body.dark .tab-title .nav-pills.group-list .nav-item a[class*=g-dot-]:before {
  position: absolute;
  padding: 4px;
  content: "";
  border-radius: 50%;
  top: 9px;
  left: 18px;
  transition: 0.6ms;
}
body.dark .tab-title .nav-pills .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  padding: 0;
  border: none;
}
body.dark .tab-title li.mail-labels a.dropdown-item {
  font-size: 13px;
  font-weight: 700;
  padding: 8px 18px;
}
body.dark .tab-title li.mail-labels a.dropdown-item:hover {
  background-color: #fff;
  color: #4361ee;
}
body.dark .tab-title li.mail-labels .label:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  right: 15px;
  top: 43%;
}

/*Mail Labels*/
body.dark .actions-btn-tooltip.tooltip {
  opacity: 1;
  top: -11px !important;
}
body.dark .actions-btn-tooltip .arrow:before {
  border-top-color: #3b3f5c;
}
body.dark .actions-btn-tooltip .tooltip-inner {
  background: #3b3f5c;
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  padding: 4px 16px;
}

/*
=====================
    Mailbox Inbox
=====================
*/
body.dark .mailbox-inbox {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 100%;
  width: 100%;
  background: #060818;
}
body.dark .mailbox-inbox .mail-menu {
  margin: 12px 13px 12px 13px;
  width: 22px;
  border-radius: 0;
  color: #515365;
  align-self: center;
}
body.dark .mailbox-inbox .search {
  display: flex;
  border-bottom: 1px solid #191e3a;
  background: #0e1726;
  border-top-right-radius: 8px;
}
body.dark .mailbox-inbox .search input {
  border: none;
  padding: 12px 13px 12px 13px;
  background-color: #0e1726;
  border-radius: 0;
  border-top-right-radius: 8px;
  box-shadow: none;
  color: #e0e6ed;
}
body.dark .mailbox-inbox .action-center {
  display: flex;
  justify-content: space-between;
  background: transparent;
  padding: 14px 16px;
  border-bottom: 1px solid #0e1726;
}
body.dark .mailbox-inbox .action-center .new-control {
  font-weight: 600;
  color: #e0e6ed;
}
body.dark .mailbox-inbox .action-center .nav-link {
  padding: 0;
  display: inline-block;
}
body.dark .mailbox-inbox .action-center .more-actions .dropdown-menu.show {
  top: 30px !important;
}
body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu {
  padding: 0;
  border: 1px solid #1b2e4b;
  min-width: 6rem;
  border-radius: 8px;
  top: 11px !important;
  left: 9px !important;
  background: #1b2e4b;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu a {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 23px 10px 43px;
  color: #888ea8;
  letter-spacing: 1px;
}
body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu a:hover {
  background-color: transparent;
  color: #2196f3;
}
body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu a[class*=g-dot-]:before {
  left: 19px;
}
body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu a.dropdown-item.active, body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu a.dropdown-item:active {
  background-color: transparent;
}
body.dark .mailbox-inbox .action-center .dropdown-menu.d-icon-menu a svg {
  vertical-align: middle;
  font-size: 15px;
  margin-right: 7px;
  color: #888ea8;
}
body.dark .mailbox-inbox .action-center .nav-link:after {
  display: none;
}
body.dark .mailbox-inbox .action-center svg {
  cursor: pointer;
  color: #888ea8;
  margin-right: 6px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
body.dark .mailbox-inbox .action-center .nav-link.label-group svg {
  margin-right: 12px;
}
body.dark .mailbox-inbox .action-center svg:not(:last-child) {
  margin-right: 12px;
}
body.dark .mailbox-inbox .action-center svg.revive-mail, body.dark .mailbox-inbox .action-center svg.permanent-delete {
  display: none;
}
body.dark .mailbox-inbox .action-center.tab-trash-active .nav-link svg {
  display: none;
}
body.dark .mailbox-inbox .action-center.tab-trash-active svg.action-important, body.dark .mailbox-inbox .action-center.tab-trash-active svg.action-spam, body.dark .mailbox-inbox .action-center.tab-trash-active svg.action-delete {
  display: none;
}
body.dark .mailbox-inbox .action-center.tab-trash-active svg.revive-mail, body.dark .mailbox-inbox .action-center.tab-trash-active svg.permanent-delete {
  display: inline-block;
}
body.dark .mailbox-inbox .more-actions svg.feather-more-vertical {
  margin-right: 0;
}
body.dark .mailbox-inbox .message-box {
  padding: 0 0 0 0;
}
body.dark .mailbox-inbox .message-box .message-box-scroll {
  position: relative;
  margin: auto;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 260px);
}
body.dark .mailbox-inbox .mail-item[id*=unread-] div.mail-item-heading .mail-item-inner .f-body .mail-title {
  font-weight: 700;
  color: #bfc9d4;
}
body.dark .mailbox-inbox .mail-item[id*=unread-] div.mail-item-heading .mail-item-inner .f-body .user-email {
  font-weight: 700;
  color: #009688;
}
body.dark .mailbox-inbox .mail-item[id*=unread-] div.mail-item-heading .mail-item-inner .mail-content-excerpt {
  font-weight: 600;
  color: #607d8b;
}
body.dark .mailbox-inbox .mail-item[id*=unread-] div.mail-item-heading .mail-item-inner .f-body .meta-time {
  font-weight: 700;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading {
  padding: 11px 10px 11px 0;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #0e1726;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading:hover {
  background: #1b2e4b;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner {
  padding-left: 15px;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .n-chk {
  align-self: center;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-head {
  align-self: center;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-head img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body {
  align-self: center;
  display: flex;
  width: 100%;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body > div.meta-title-tag {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body > div.meta-mail-time {
  display: flex;
  justify-content: space-between;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .user-email {
  padding: 0 15px 0 20px;
  min-width: 215px;
  max-width: 215px;
  font-size: 15px;
  color: #607d8b;
  margin-bottom: 0;
  letter-spacing: 0px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  align-self: center;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .meta-time {
  margin-bottom: 0;
  float: right;
  font-weight: 500;
  font-size: 12px;
  min-width: 75px;
  max-width: 80px;
  text-align: right;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .mail-title {
  font-size: 15px;
  color: #bfc9d4;
  margin-bottom: 2px;
  letter-spacing: 0px;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .tags {
  position: relative;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .tags span {
  display: none;
  margin-left: 11px;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading.personal .mail-item-inner .f-body .tags span.g-dot-primary, body.dark .mailbox-inbox .mail-item div.mail-item-heading.work .mail-item-inner .f-body .tags span.g-dot-warning, body.dark .mailbox-inbox .mail-item div.mail-item-heading.social .mail-item-inner .f-body .tags span.g-dot-success, body.dark .mailbox-inbox .mail-item div.mail-item-heading.private .mail-item-inner .f-body .tags span.g-dot-danger {
  display: inline-block;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .tags span[class*=g-dot-]:before {
  top: -11px;
  left: -13px;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt {
  font-size: 14px;
  margin-bottom: 0;
  color: #607d8b;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: calc(100vw - 830px);
  align-self: center;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt svg.attachment-indicator {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: top;
}
body.dark .mailbox-inbox .mail-item.sentmail div.mail-item-heading .mail-item-inner .mail-content-excerpt, body.dark .mailbox-inbox .mail-item.draft div.mail-item-heading .mail-item-inner .mail-content-excerpt {
  margin-left: 31px;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .attachments {
  margin: 0 auto;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: calc(100vw - 830px);
  display: none;
}
body.dark .mailbox-inbox .mail-item div.mail-item-heading .attachments span {
  display: inline-block;
  border: 1px solid #009688;
  padding: 1px 11px;
  border-radius: 30px;
  color: #0e1726;
  background: #009688;
  font-size: 12px;
  margin-right: 3px;
  font-weight: 700;
  margin-bottom: 2px;
  letter-spacing: 0px;
  max-width: 96px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/*
=====================
    Content Box
=====================
*/
body.dark .content-box {
  background-color: #0e1726;
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px;
  left: auto;
  right: -46px;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
body.dark .content-box .msg-close {
  padding: 13px;
  background: #0e1726;
  border-bottom: 1px solid #191e3a;
}
body.dark .content-box svg.close-message {
  font-size: 15px;
  color: #bfc9d4;
  padding: 3px;
  align-self: center;
  cursor: pointer;
  margin-right: 12px;
}
body.dark .content-box .mail-title {
  font-size: 24px;
  font-weight: 600;
  color: #22c7d5;
  margin-bottom: 0;
  align-self: center;
}
body.dark .mailbox-inbox .collapse {
  position: relative;
  height: calc(100vh - 213px);
}
body.dark .mailbox-inbox .mail-content-container {
  position: relative;
  height: auto;
  overflow: auto;
  padding: 25px;
  border-radius: 8px;
}
body.dark .mailbox-inbox .mail-content-container .user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
  border: 3px solid #0e1726;
}
body.dark .mailbox-inbox .mail-content-container .user-info .avatar {
  margin-right: 8px;
}
body.dark .mailbox-inbox .mail-content-container .user-info .f-body {
  align-self: center;
}
body.dark .mailbox-inbox .mail-content-container .user-info .meta-title-tag .mail-usr-name {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #2196f3;
}
body.dark .mailbox-inbox .mail-content-container .user-info .user-email {
  margin-bottom: 0;
  font-weight: 600;
  display: inline-block;
}
body.dark .mailbox-inbox .mail-content-container .user-info .user-email span {
  font-size: 16px;
  font-weight: 700;
}
body.dark .mailbox-inbox .mail-content-container .user-info .user-cc-mail {
  margin-bottom: 0;
  font-weight: 600;
  margin-left: 8px;
  display: inline-block;
}
body.dark .mailbox-inbox .mail-content-container .user-info .user-cc-mail span {
  font-size: 16px;
  font-weight: 700;
}
body.dark .mailbox-inbox .mail-content-container .user-info .meta-mail-time .meta-time {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0;
}
body.dark .mailbox-inbox .mail-content-container .mail-content-meta-date {
  font-size: 13px;
  font-weight: 600;
  color: #e0e6ed;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0;
}
body.dark .mailbox-inbox .mail-content-container .action-btns a {
  margin-right: 20px;
}
body.dark .mailbox-inbox .mail-content-container .action-btns svg {
  color: #181e2e;
  font-weight: 600;
}
body.dark .mailbox-inbox .mail-content-container .action-btns svg.restore {
  position: relative;
}
body.dark .mailbox-inbox .mail-content-container .action-btns svg.restore:after {
  content: "";
  height: 28px;
  width: 2px;
  background: #181e2e;
  position: absolute;
  border-radius: 50px;
  left: 9px;
  transform: rotate(30deg);
  top: -3px;
}
body.dark .mailbox-inbox .mail-content-container .mail-content-title {
  font-weight: 600;
  font-size: 20px;
  color: #515365;
  margin-bottom: 25px;
}
body.dark .mailbox-inbox .mail-content-container p {
  font-size: 14px;
  color: #bfc9d4;
}
body.dark .mailbox-inbox .mail-content-container p.mail-content {
  padding-top: 45px;
  border-top: 1px solid #191e3a;
  margin-top: 20px;
}
body.dark .mailbox-inbox .mail-content-container .attachments {
  margin-top: 55px;
  margin-bottom: 0;
}
body.dark .mailbox-inbox .mail-content-container .attachments .attachments-section-title {
  font-weight: 600;
  color: #888ea8;
  font-size: 16px;
  border-bottom: 1px solid #191e3a;
  padding-bottom: 9px;
  margin-bottom: 20px;
}
body.dark .mailbox-inbox .mail-content-container .attachment {
  display: inline-block;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  min-width: 150px;
  max-width: 235px;
}
body.dark .mailbox-inbox .mail-content-container .attachment svg {
  font-size: 18px;
  margin-right: 13px;
  color: #009688;
  align-self: center;
}
body.dark .mailbox-inbox .mail-content-container .attachment .file-name {
  color: #e0e6ed;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  word-break: break-word;
}
body.dark .mailbox-inbox .mail-content-container .attachment .file-size {
  color: #e0e6ed;
  font-size: 11px;
  text-align: left;
  font-weight: 600;
  margin-bottom: 0;
}
body.dark #editor-container {
  height: 200px;
}
body.dark .ql-toolbar.ql-snow {
  border: 1px solid #191e3a;
  margin-top: 30px;
}
body.dark .ql-container.ql-snow {
  border: 1px solid #191e3a;
}
body.dark .modal-content .modal-body .compose-box p {
  color: #bfc9d4;
}
body.dark .modal-content .modal-body .compose-box p svg {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  color: #009688;
}
body.dark input[type=file]::file-selector-button, body.dark input[type=file]::-webkit-file-upload-button {
  background-color: #1b2e4b !important;
  color: #fff;
}
body.dark .ql-editor.ql-blank::before {
  color: #bfc9d4;
}
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
body.dark .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}
body.dark .animatedFadeInUp {
  opacity: 0;
}
body.dark .fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
@media (min-width: 1200px) {
  body.dark .main-container:not(.sidebar-closed) .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt {
    width: calc(100vw - 980px) !important;
  }
}
@media (min-width: 992px) {
  body.dark .main-container:not(.sidebar-closed) .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt {
    width: calc(100vw - 808px);
  }
  body.dark .main-container:not(.sidebar-closed) .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .user-email {
    min-width: 170px;
    max-width: 170px;
  }
  body.dark .main-container:not(.sidebar-closed) .mailbox-inbox .mail-item div.mail-item-heading .attachments {
    width: calc(100vw - 940px);
  }
}
@media (max-width: 991px) {
  body.dark .mail-box-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
  body.dark .mailbox-inbox .search input {
    border-left: 1px solid #3b3f5c;
  }
  body.dark .tab-title {
    position: absolute;
    z-index: 4;
    left: -147px;
    width: 0;
    background: #0e1726;
  }
  body.dark .tab-title.mail-menu-show {
    left: 0;
    width: 100%;
    min-width: 111px;
  }
  body.dark .mailbox-inbox {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  body.dark .mailbox-inbox .mail-menu {
    margin: 12px 13px 8px 13px;
  }
  body.dark .mailbox-inbox .search {
    background-color: #0e1726;
    padding: 0;
  }
  body.dark .mailbox-inbox .action-center {
    padding: 14px 14px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading:hover {
    background: transparent;
    border: none !important;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner {
    padding-left: 14px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt {
    width: calc(100vw - 527px);
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .user-email {
    min-width: 170px;
    max-width: 170px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .attachments {
    width: calc(100vw - 527px);
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  body.dark .new-control.new-checkbox .new-control-indicator {
    margin-right: 10px;
  }
  body.dark .mailbox-inbox {
    display: block;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading {
    margin: 0;
    padding: 20px 10px 20px 0;
    border: none;
    border-radius: 0;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-head img {
    width: 35px;
    height: 35px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body {
    display: block;
  }
  body.dark .mailbox-inbox .message-box {
    width: 100%;
    margin-bottom: 40px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body > div.meta-title-tag {
    padding-left: 10px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .user-email {
    padding: 0 0 0 10px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .meta-time {
    min-width: auto;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt {
    width: calc(100vw - 192px);
    padding-right: 7px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .tags {
    position: absolute;
    right: 5px;
    top: 23px;
    width: 60px;
  }
  body.dark .mailbox-inbox .mail-item.sentmail div.mail-item-heading .mail-item-inner .mail-content-excerpt, body.dark .mailbox-inbox .mail-item.draft div.mail-item-heading .mail-item-inner .mail-content-excerpt {
    margin-left: 0;
    width: calc(100vw - 178px);
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .attachments {
    width: calc(100vw - 192px);
    padding: 0 11px;
  }
  body.dark .mailbox-inbox .mail-item.sentmail div.mail-item-heading .attachments {
    margin: 0 0 0 40px;
  }
}
@media (max-width: 575px) {
  body.dark .mailbox-inbox .message-box {
    margin-bottom: 0;
  }
  body.dark .mailbox-inbox .mail-content-container .user-info {
    display: block !important;
  }
  body.dark .mailbox-inbox .mail-content-container .user-info img {
    margin-bottom: 10px;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body > div {
    display: block;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body > div.meta-mail-time {
    display: block;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .f-body .meta-time {
    margin-bottom: 0;
    float: none;
  }
  body.dark .mailbox-inbox .mail-item div.mail-item-heading .mail-item-inner .mail-content-excerpt {
    margin-left: 0;
    margin-right: 0;
    width: calc(100vw - 215px);
  }
  body.dark .mailbox-inbox .mail-content-container .action-btns a {
    margin-right: 0;
  }
  body.dark .compose-box .compose-content form .mail-form select {
    margin-left: 3px;
    margin-top: 10px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body.dark {
    /* IE10+ CSS styles go here */
  }
  body.dark .tab-title {
    width: 100%;
  }
  body.dark .mailbox-inbox .mail-content-container .attachment .media .media-body {
    flex: none;
  }
}

