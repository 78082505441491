/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark .tooltip-inner {
  border-radius: 6px;
}
body.dark .tooltip .tooltip-item {
  color: #fff;
  padding: 0 9px;
}
body.dark .tooltip-section h6 {
  color: #3b3f5c;
  font-size: 0.875rem;
  margin-top: 25px;
  margin-bottom: 20px;
}
body.dark .tooltip .tooltip-inner {
  background-color: #060818;
}
body.dark .bs-tooltip-top .tooltip-tooltip-arrow::before {
  border-top-color: #060818;
}

/*
    ==================
        Colors
    =================
*/
/*
    Tooltips
*/
/*		Tooltip Inner 	*/
body.dark .tooltip-primary .tooltip-inner {
  color: #4361ee;
  background-color: #060818;
}
body.dark .tooltip-success .tooltip-inner {
  color: #00ab55;
  background-color: #060818;
}
body.dark .tooltip-info .tooltip-inner {
  color: #2196f3;
  background-color: #060818;
}
body.dark .tooltip-danger .tooltip-inner {
  color: #e7515a;
  background-color: #060818;
}
body.dark .tooltip-warning .tooltip-inner {
  color: #e2a03f;
  background-color: #060818;
}
body.dark .tooltip-secondary .tooltip-inner {
  color: #805dca;
  background-color: #060818;
}
body.dark .tooltip-dark .tooltip-inner {
  color: #bfc9d4;
  background-color: #060818;
}

/*		Tooltip arrow 		*/
body.dark .tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #060818;
}
body.dark .tooltip.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: #060818;
}
body.dark .tooltip.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: #060818;
}
body.dark .tooltip-primary.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip-info.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip-success.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip-warning.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip-danger.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip-secondary.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}
body.dark .tooltip-dark.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #060818;
}

