/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark .row .col-lg-12 .widget .widget-header h4 {
  /* color: #000000; */
  font-size: 20px;
}
body.dark body {
  color: #515365;
  font-weight: 600;
}
body.dark .form-control {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  color: #F49499;
}
body.dark .form-control:disabled, body.dark .form-control[readonly] {
  background-color: #f1f2f3;
}
body.dark .form-control:focus {
  border-color: #805dca;
}
body.dark .help-block, body.dark .help-inline {
  color: #888ea8;
}
body.dark .input-group-addon {
  background-color: #ebedf2;
  color: #3b3f5c;
}
body.dark .dropdown-toggle:after {
  color: #3b3f5c;
}
body.dark .has-warning .control-label, body.dark .has-warning .help-block {
  color: #ffbb44;
}
body.dark .has-warning .form-control {
  border-color: #ffbb44;
}
body.dark .has-error .control-label, body.dark .has-error .help-block {
  color: #ee3d49;
}
body.dark .has-error .form-control {
  border-color: #ee3d49;
}
body.dark .has-success .control-label, body.dark .has-success .help-block {
  color: #009688;
}
body.dark .has-success .form-control {
  border-color: #009688;
}
body.dark .ui-spinner-button {
  border: 1px solid #ebedf2;
  color: #d3d3d3;
}
body.dark .form-horizontal .radio, body.dark .form-horizontal .radio-inline {
  color: #888ea8;
  font-weight: normal;
}
body.dark div.tagsinput {
  border: 1px solid #ebedf2;
}
body.dark div.tagsinput span.tag {
  background: #edf1f7;
  border: 1px solid #ebedf2;
}
body.dark .select2-container .select2-choice {
  border: 1px solid #ebedf2;
  color: #888ea8;
  font-size: 13px;
  font-weight: normal;
}
body.dark .select2-default {
  color: #888ea8 !important;
  font-size: 13px !important;
  font-weight: normal;
}
body.dark .select2-container .select2-choice .select2-arrow {
  border: 1px solid #f1f2f3;
  background: #fff;
}
body.dark .select2-container-multi .select2-choices {
  border: 1px solid #ebedf2;
}
body.dark .select2-container-multi .select2-choices .select2-search-choice {
  border: 1px solid #f1f2f3;
  color: #888ea8;
  font-weight: normal;
  font-size: 13px;
}
body.dark .checkbox, body.dark .radio {
  position: relative;
  display: block;
  cursor: pointer;
}
body.dark .checkbox-inline, body.dark .radio-inline {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
body.dark .form-horizontal .checkbox, body.dark .form-horizontal .checkbox-inline, body.dark .form-horizontal .radio, body.dark .form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
body.dark .form-horizontal .checkbox, body.dark .form-horizontal .radio {
  min-height: 27px;
}

