@import '../../base/base';
body.dark {
    .layout-spacing {
        padding-bottom: 25px;
      }
      
      .widget {
        position: relative;
        padding: 0;
        border-radius: 6px;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        box-shadow: 0 3px 8px 0 rgba(85, 85, 85, 0.08), 0 1px 0px 0 rgba(0, 0, 0, 0.07), 0px 1px 4px 0px rgba(0, 0, 0, 0.07);
        box-shadow: 0 0.1px 0px rgba(0, 0, 0, 0.002), 0 0.2px 0px rgba(0, 0, 0, 0.003), 0 0.4px 0px rgba(0, 0, 0, 0.004), 0 0.6px 0px rgba(0, 0, 0, 0.004), 0 0.9px 0px rgba(0, 0, 0, 0.005), 0 1.2px 0px rgba(0, 0, 0, 0.006), 0 1.8px 0px rgba(0, 0, 0, 0.006), 0 2.6px 0px rgba(0, 0, 0, 0.007), 0 3.9px 0px rgba(0, 0, 0, 0.008), 0 7px 0px rgba(0, 0, 0, 0.01);
      }
      
      .apexcharts-xaxis text, .apexcharts-yaxis text {
        fill: #888ea8;
      }
      
      .apexcharts-legend-text {
        color: #bfc9d4 !important;
      }
      
      .apexcharts-tooltip.apexcharts-theme-dark {
        background: #191e3a !important;
        box-shadow: none;
      
        .apexcharts-tooltip-title {
          background: #191e3a !important;
          border-bottom: 1px solid #191e3a;
        }
      }

}