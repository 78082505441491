@import '../../base/base';
  /*
      =====================
          Task Indicator
      =====================
  */
  body.dark {
    .widget-five {
      background: #0e1726;
      padding: 20px 0px !important;
      height: 100%;
    
      .widget-heading {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        margin-bottom: 30px;
    
        .task-info {
          display: flex;
        }
    
        .usr-avatar {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 42px;
          height: 42px;
          border-radius: 12px;
          background-color: $secondary;
          color: #ebedf2;
    
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
    
        .w-title {
          align-self: center;
    
          h5 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
          }
    
          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
    
        .task-action {
          .dropdown a svg {
            color: #888ea8;
            width: 19px;
            height: 19px;
          }
    
          .dropdown-menu {
            transform: translate3d(-141px, 0, 0px);
          }
        }
      }
    
      .widget-content {
        padding: 0 20px;
    
        p {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 14px;
          color: #888ea8;
        }
    
        .progress-data {
          margin-top: 19px;
    
          .progress-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
          }
    
          .task-count {
            display: flex;
    
            svg {
              align-self: center;
              margin-right: 6px;
              width: 15px;
              height: 15px;
              color: #009688;
            }
    
            p {
              align-self: center;
              font-weight: 700;
              font-size: 12px;
            }
          }
    
          .progress-stats p {
            font-weight: 600;
            color: $info;
            font-size: 15px;
          }
    
          .progress {
            border-radius: 30px;
            height: 12px;
    
            .progress-bar {
              margin: 3px;
              background-color: #60dfcd;
              background-image: linear-gradient(315deg, #60dfcd 0%, #1e9afe 74%);
            }
          }
        }
    
        .meta-info {
          display: flex;
          justify-content: space-between;
    
          .avatar--group {
            display: inline-flex;
          }
    
          .avatar {
            position: relative;
            display: inline-block;
            width: 36px;
            height: 36px;
            font-size: 1rem;
            transition: .5s;
    
            &.more-group {
              margin-right: 5px;
              opacity: 0;
            }
          }
        }
      }
    
      &:hover .widget-content .meta-info .avatar {
        &.more-group {
          opacity: 1;
        }
    
        &:not(:first-child) {
          margin-left: -.75rem;
        }
      }
    
      .widget-content .meta-info {
        .avatar {
          img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            border: 2px solid $dark;
            border-radius: 12px;
          }
    
          .avatar-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: #bfc9d4;
            color: $dark;
            border-radius: 12px;
            font-size: 14px;
            font-weight: 600;
            border: none;
          }
        }
    
        .due-time {
          align-self: center;
    
          p {
            svg {
              width: 14px;
              height: 15px;
              vertical-align: text-bottom;
              margin-right: 2px;
            }
    
            font-weight: 500;
            font-size: 11px;
            padding: 4px 6px 4px 6px;
            background: $dark;
            border-radius: 30px;
            color: #bfc9d4;
          }
        }
      }
    }
  }