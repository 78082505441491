@import '../../base/base';
body.dark {
  .widget.widget-card-five {
    padding: 25px 23px;
    background-color: #0e1726;
    overflow: hidden;
    
    .account-box {
      .info-box {
        display: flex;
        justify-content: space-between;

        .icon {

          &:before {
            content: '';
            background: #1d1a3b;
            position: absolute;
            top: -29px;
            left: -34px;
            height: 150px;
            width: 150px;
            border-radius: 50%;
          }

          span {
            display: inline-block;
            position: absolute;
            top: 12px;
            left: -1px;

            img {
              width: 90px;
              height: 90px;
            }
          }
          
          svg {
            width: 22px;
            height: 22px;
          }
        }
        
        .balance-info {
          text-align: right;
          h6 {
            margin-bottom: 0;
            font-size: 17px;
            color: #e95f2b;
          }
          p {
            margin-bottom: 0;
            font-size: 25px;
            font-weight: 600;
            color: #bfc9d4;
          }
        }
      }
      
      .card-bottom-section {
        display: flex;
        justify-content: space-between;
        margin-top: 82px;
        align-items: end;
        p {

          svg {
            width: 15px;
            height: 15px;
            stroke-width: 1.5px;
          }
        }
        a {            
          font-weight: 600;
          border-bottom: 1px dashed;
          color: #008eff;

          &:hover {
            color: #2196f3;
          }

        }
      }
    }
  }
}