/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
 * Container style
 */
body.dark .ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
body.dark .ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 10px;
  bottom: 0px;
  position: absolute;
}

body.dark .ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 10px;
  right: 0;
  position: absolute;
}

body.dark .ps--active-x > .ps__rail-x, body.dark .ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
body.dark .ps:hover > .ps__rail-x, body.dark .ps:hover > .ps__rail-y {
  opacity: 0.6;
}
body.dark .ps--focus > .ps__rail-x, body.dark .ps--focus > .ps__rail-y {
  opacity: 0.6;
}
body.dark .ps--scrolling-x > .ps__rail-x, body.dark .ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}
body.dark .ps .ps__rail-x:hover, body.dark .ps .ps__rail-y:hover, body.dark .ps .ps__rail-x:focus, body.dark .ps .ps__rail-y:focus, body.dark .ps .ps__rail-x.ps--clicking, body.dark .ps .ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
body.dark .ps__thumb-x {
  background-color: #888ea8;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  bottom: 2px;
  position: absolute;
}
body.dark .ps__thumb-y {
  background-color: #515365;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  right: 2px;
  position: absolute;
}
body.dark .ps__rail-x:hover > .ps__thumb-x, body.dark .ps__rail-x:focus > .ps__thumb-x, body.dark .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #3b3f5c;
  height: 6px;
}
body.dark .ps__rail-y:hover > .ps__thumb-y, body.dark .ps__rail-y:focus > .ps__thumb-y, body.dark .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #3b3f5c;
  width: 6px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  body.dark .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body.dark .ps {
    overflow: auto !important;
  }
}

