@import '../../base/base';
/*
    ========================
        Recent Activities
    ========================
*/

body.dark {

  .widget {

    &.widget-activity-five {
        position: relative;
        background: #0e1726;
        border-radius: 6px;
        height: 100%;
        padding: 0;
    
        .widget-heading {
          display: flex;
          justify-content: space-between;
          padding: 20px 20px;
          padding-bottom: 20px;
          margin-bottom: 0;
    
          h5 {
            font-size: 17px;
            display: block;
            color: #e0e6ed;
            font-weight: 600;
            margin-bottom: 0;
          }
    
          .task-action {
            svg {
              color: #888ea8;
              width: 19px;
              height: 19px;
            }
    
            .dropdown-menu {
              transform: translate3d(-141px, 0, 0px);
            }
          }
        }

        .widget-content {
            padding: 12px 10px 21px 20px;
        }
        
          .w-shadow-top {
            display: block;
            position: absolute;
            z-index: 2;
            height: 17px;
            width: 97%;
            pointer-events: none;
            margin-top: -10px;
            left: 2px;
            -webkit-filter: blur(9px);
            filter: blur(9px);
            background: -webkit-linear-gradient(180deg, #0e1726 44%, #0e1726d1 73%, #2C303C00);
            background: linear-gradient(180deg, #0e1726 44%, #0e1726d1 73%, #2C303C00);
          }
        
          .w-shadow-bottom {
            display: block;
            position: absolute;
            z-index: 2;
            height: 17px;
            width: 97%;
            pointer-events: none;
            margin-top: -3px;
            left: 2px;
            -webkit-filter: blur(9px);
            filter: blur(9px);
            background: -webkit-linear-gradient(180deg, #0e1726 44%, #0e1726d4 73%, #2C303C00);
            background: linear-gradient(180deg, #0e1726 44%, #0e1726d4 73%, #2C303C00);
          }
        
          .mt-container {
            position: relative;
            height: 332px;
            overflow: auto;
            padding: 15px 12px 0 12px;
          }
        
          .timeline-line .item-timeline {
            display: flex;
            margin-bottom: 35px;
        
            .t-dot {
              position: relative;
        
              div {
                background: transparent;
                border-radius: 50%;
                padding: 5px;
                margin-right: 11px;
                display: flex;
                height: 32px;
                justify-content: center;
                width: 32px;
                box-shadow: none;
        
                &.t-primary {
                  background-color: $primary;
        
                  svg {
                    color: #e0e6ed;
                  }
                }
        
                &.t-secondary {
                  background-color: $secondary;
        
                  svg {
                    color: #e0e6ed;
                  }
                }
        
                &.t-success {
                  background-color: #009688;
        
                  svg {
                    color: #e0e6ed;
                  }
                }
        
                &.t-danger {
                  background-color: $danger;
        
                  svg {
                    color: #e0e6ed;
                  }
                }
        
                &.t-warning {
                  background-color: $warning;
        
                  svg {
                    color: #e0e6ed;
                  }
                }
        
                &.t-dark {
                  background-color: $dark;
        
                  svg {
                    color: #e0e6ed;
                  }
                }
              }
        
              svg {
                color: #fff;
                height: 15px;
                width: 15px;
                align-self: center;
              }
            }
        
            .t-content {
              width: 100%;
        
              .t-uppercontent {
                display: flex;
                justify-content: space-between;
        
                h5 {
                  font-size: 14px;
                  letter-spacing: 0;
                  font-weight: 500;
                  margin-bottom: 0;
                }
        
                span {
                  margin-bottom: 0;
                  font-size: 13px;
                  font-weight: 600;
                  color: #009688;
                }
              }
        
              p {
                margin-bottom: 0;
                font-size: 12px;
                font-weight: 600;
                color: #888ea8;
        
                a {
                  font-weight: 700;
                }
              }
            }
        
            .t-dot:after {
              content: '';
              position: absolute;
              border-width: 1px;
              border-style: solid;
              left: 39%;
              transform: translateX(-50%);
              border-color: $dark;
              width: 0;
              height: auto;
              top: 45px;
              bottom: -23px;
              border-right-width: 0;
              border-top-width: 0;
              border-bottom-width: 0;
              border-radius: 0;
            }
        
            &:last-child .t-dot:after {
              display: none;
            }
        }
    }
  }
      
    @media (max-width: 1199px) {
      .widget {
        &.widget-activity-five .mt-container {
          height: 205px;
        }
      }
    }
}