/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark .nav.nav-tabs {
  border: none;
}
body.dark .nav.nav-tabs li.nav-item button.nav-link {
  border: none;
  font-weight: 400;
  padding: 8px 14px;
  letter-spacing: 1px;
  color: #bfc9d4;
  background: transparent;
}
body.dark .nav.nav-tabs li.nav-item button.nav-link svg {
  vertical-align: text-bottom;
  stroke-width: 1.6;
  width: 20px;
  height: 20px;
}
body.dark .nav.nav-tabs li.nav-item button.nav-link.active {
  border-bottom: 1.6px solid #008eff;
  color: #008eff;
}
body.dark .nav.nav-tabs li.nav-item button.nav-link.active svg {
  color: #008eff;
}
body.dark .nav.nav-tabs li.nav-item button.nav-link:disabled {
  opacity: 0.5;
}
body.dark .nav.nav-pills {
  border: none;
  padding: 8px;
  background: #1b2e4b;
  border-radius: 8px;
}
body.dark .nav.nav-pills li.nav-item button.nav-link {
  border: none;
  font-weight: 500;
  padding: 8px 14px;
  letter-spacing: 1px;
  color: #bfc9d4;
}
body.dark .nav.nav-pills li.nav-item button.nav-link svg {
  vertical-align: bottom;
  stroke-width: 1.6;
  color: #bfc9d4;
  width: 20px;
  height: 20px;
}
body.dark .nav.nav-pills li.nav-item button.nav-link.active {
  border-bottom: none;
  background-color: #0e1726;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}
body.dark .nav.nav-pills li.nav-item button.nav-link:disabled {
  opacity: 0.5;
}
body.dark .nav.nav-pills[aria-orientation=vertical] button.nav-link {
  border: none;
  font-weight: 500;
  padding: 8px 14px;
  letter-spacing: 1px;
  color: #bfc9d4;
}
body.dark .nav.nav-pills[aria-orientation=vertical] button.nav-link svg {
  vertical-align: bottom;
  stroke-width: 1.6;
  color: #bfc9d4;
  width: 20px;
  height: 20px;
}
body.dark .nav.nav-pills[aria-orientation=vertical] button.nav-link.active {
  border-bottom: none;
  background-color: #0e1726;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}
body.dark .nav.nav-pills[aria-orientation=vertical] button.nav-link:disabled {
  opacity: 0.5;
}

