/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*  Tree View   */
body.dark .treeview {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
body.dark .treeview li p {
  font-size: 13px;
  color: #888ea8;
  font-weight: 500;
}
body.dark .treeview .tv-item .tv-header {
  padding: 6px 0;
  cursor: pointer;
}
body.dark .treeview .tv-item .tv-header .tv-collapsible {
  display: flex;
}
body.dark .treeview .tv-item .tv-header .tv-collapsible .icon {
  margin-right: 6px;
  align-self: center;
}
body.dark .treeview .tv-item .tv-header .tv-collapsible .icon svg {
  width: 14px;
  height: 14px;
  transition: 0.5s;
}
body.dark .treeview .tv-item .tv-header .tv-collapsible .title {
  margin-bottom: 0;
  align-self: center;
  font-size: 14px;
  color: #bfc9d4;
  font-weight: 500;
}
body.dark .treeview .tv-item .tv-header .tv-collapsible:not(.collapsed) .icon svg.icon-tabler-chevron-right {
  transform: rotate(90deg);
  color: #fff;
}
body.dark .treeview .treeview {
  margin-left: 30px;
  list-style: none;
  padding: 0;
}
body.dark .treeview.folder-structure .tv-item .tv-header {
  padding: 6px 0;
  cursor: pointer;
}
body.dark .treeview.folder-structure .tv-item .tv-header .tv-collapsible {
  display: flex;
}
body.dark .treeview.folder-structure .tv-item .tv-header .tv-collapsible .icon {
  margin-right: 6px;
  align-self: center;
}
body.dark .treeview.folder-structure .tv-item .tv-header .tv-collapsible .icon svg {
  width: 20px;
  height: 20px;
  transition: 0.5s;
  color: #e2a03f;
  fill: #e2a03f;
}
body.dark .treeview.folder-structure .tv-item .tv-header .tv-collapsible:not(.collapsed) .icon svg {
  color: #e2a03f;
  fill: rgba(226, 160, 63, 0.4);
}

